import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import { Image } from "@global";
import { assets, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";

const ArrowContainer = styled(Container)<{ isHomepage?: boolean; }>`
  position: absolute;
  width: 34px;
  height: 17px;
  bottom: ${props => props.isHomepage ? "-96vh" : "-76vh"};
  transform: rotate(90deg);
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    left: ${props => props.isHomepage ? "0" : "-36vw"};
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    left: ${props => props.isHomepage ? "0" : "-34vw"};
    width: 27px;
    height: 13px;
  }
`;

interface Props {
  isHomepage?: boolean;
}

const AnimatedArrowBlock = ({isHomepage}: Props) => {
  const [styles, api] = useSpring(() => ({
    from: { y: 5, opacity: 1 },
  }));
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (hover) {
      api.start({
        y: 15,
        opacity: 1,
      });
    } else {
      api.start({
        y: 5,
        opacity: 1,
      });
    }
  }, [hover]);

  const handleScroll = () => {
    if (typeof window !== undefined) {
      const scrollValue = window.innerHeight - 140;
      window.scrollTo({ top: scrollValue, behavior: "smooth" });
    }
    return;
  };

  const AnimatedArrow = () => (
    <animated.div
      style={{
        ...styles,
      }}
      >
      <ArrowContainer isHomepage={isHomepage}>
        <Image
          staticImage={assets.ctaArrow}
          />
      </ArrowContainer>
    </animated.div>
  );
  return (
    <Container
      cursor="pointer"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleScroll}
    >
      <AnimatedArrow />
    </Container>
  );
};

export default AnimatedArrowBlock;