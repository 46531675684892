import React from "react";
import { Helmet } from "react-helmet";

import { Maybe, SanityHero, SanityRegion, SanitySeo } from "@graphql-types";
import { setBaseUrl } from "@util/helper";
import { useStore } from "@state/store";
import { useDefaultSeoQuery } from "@api";
import { isSanityFlightImage } from "@util/types";

interface Props {
  seoData?: SanitySeo | null;
  post?: boolean;
  overwriteTitle?: string;
  overwriteImage?: string;
  overwriteDescription?: string;
  slug?: string;
  heroData?: Maybe<SanityHero> | undefined;
  alternateRegions?: (Maybe<SanityRegion> | undefined)[];
}

const SEO = (props: Props) => {
  const {
    region: { iban },
  } = useStore();
  const defaultSEO = useDefaultSeoQuery(iban);
  const { slug, alternateRegions, heroData, seoData} = props;

  const urlString = setBaseUrl(slug, iban);
  let heroTitle, heroDescription, heroImage;
  if (heroData?.blockContent?._rawColumnContent != null) {
    heroData?.blockContent?._rawColumnContent.forEach(content => {
      const tag = content.style ?? "normal";
      if (/^h\d/.test(tag)) {
        heroTitle = content.children.map(child => child.text).join('');
      } else {
        heroDescription = content.children.map(child => child.text).join('');
      }
    });
  }
  if (heroData?.media && heroData.media[0] && isSanityFlightImage(heroData.media[0])) {
    if (heroData.media[0].asset?.gatsbyImageData?.images?.fallback?.src != null) {
      heroImage = heroData.media[0].asset.gatsbyImageData.images.fallback.src;
    }
  }

  const title = props.overwriteTitle || (seoData?.pageTitle as string) || heroTitle || (defaultSEO?.pageTitle as string);
  const description = seoData?.pageDescription || (props.overwriteDescription as string) || heroDescription || (defaultSEO?.pageDescription as string);
  const image = seoData?.ogImage?.asset?.url || (props.overwriteImage as string) || heroImage || (defaultSEO?.ogImage?.asset?.url as string);
  const keyword = seoData?.pageKeyWords || (defaultSEO?.pageKeyWords as string);

  return (
    <div className="seo">
      <Helmet
      title={title}
    >
      {alternateRegions &&
        alternateRegions.length > 0 &&
        alternateRegions.map(alt => {
          if (alt == null ) {
            return null;
          }
          return (
            <link
              key={alt.iban}
              rel="alternate"
              hrefLang={alt.hrefLang as string}
              href={setBaseUrl(slug, alt.iban as string)}
            />
          );
        })}
      <meta
        name="description"
        content={description}
      />
      <meta
        name="keywords"
        content={keyword}
      />
      <html lang="en" />

      <meta
        property="og:title"
        content={title}
      />
      <meta property="og:type" content={props.post ? `article` : `website`} />
      <meta property="og:url" content={urlString} />
      <meta
        property="og:image"
        content={image}
      />
      <meta
        property="og:description"
        content={description}
      />

      {/* Twitter Card tags */}
      <meta
        name="twitter:card"
        content={description}
      />
      <meta
        name="twitter:title"
        content={title}
      />
      <meta
        name="twitter:image"
        content={image}
      />
      <meta property="twitter:site" content={urlString} />
      <meta
        name="twitter:description"
        content={description}
      />
    </Helmet>
    </div>
  );
};

export default SEO;
