import React from "react";
import { graphql, PageProps } from "gatsby";

import Hero from "@shared/hero";
import SEO from "@shared/seo";
import { Query, SanityCommunityConnection } from "@graphql-types";
import {
  isSanityFullWidthImageBlock,
  isSanityLeftRightBlock,
  isSanityCarouselWithParagraph,
} from "@util/types";
import {
  CarouselWithParagraph,
  FullWithImageBlock,
  LeftRightBlock,
} from "@global";
import { useRegionChanged } from "@util/hooks";

interface Data extends Query {
  altRegions: SanityCommunityConnection;
}
interface Props extends PageProps {
  data: Data;
  pageContext: { iban: string };
}

export default function CommunityPage({ data, pageContext }: Props) {
  const { nodes } = data.allSanityCommunity;
  const { communityHero, seo, components } = nodes[0];
  const alternateSlugs = data.altRegions.nodes.map((alt) => alt.region);
  useRegionChanged("community", pageContext.iban);

  return (
    <div>
      <SEO seoData={seo} slug="community" alternateRegions={alternateSlugs} heroData={communityHero}/>
      <Hero heroData={communityHero} />
      {components &&
        components.map((data) => {
          if (isSanityLeftRightBlock(data)) {
            return <LeftRightBlock data={data} key={data?._key} />;
          }
          if (isSanityFullWidthImageBlock(data)) {
            return <FullWithImageBlock data={data} key={data?._key} />;
          }
          if (isSanityCarouselWithParagraph(data)) {
            return <CarouselWithParagraph data={data} key={data?._key} />;
          }
          return null;
        })}
    </div>
  );
}

export const query = graphql`
  query CommunityPageQuery($iban: String) {
    allSanityCommunity(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        communityHero {
          ...sanityHero
        }
        region {
          ...sanityRegion
        }
        seo {
          ...sanitySeo
        }
        components {
          ... on SanityFullWidthImageBlock {
            ...sanityFullWidthImageBlock
          }
          ... on SanityCarouselWithParagraph {
            ...sanityCarouselWithParagraph
          }
          ... on SanityLeftRightBlock {
            ...sanityLeftRightBlock
          }
        }
      }
    }
    altRegions: allSanityCommunity {
      nodes {
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
