import React from "react";
import styled from "styled-components";

import { Maybe, SanityFlightImageOrVideo, SanityHero } from "@graphql-types";
import { Container, P } from "@util/standard";
import { Video, Image, BlocksContent, DefaultButton } from "@global";
import { isSanityFlightImage, isSanityVideoUpload } from "@util/types";
import {
  colors,
  colorsRGB,
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "@util/constants";
import AnimatedArrowBlock from "./animatedArrow";

const Overlay = styled.div<{ isHomepage?: boolean }>`
  position: absolute;
  height: ${(props) => (props.isHomepage ? "100vh" : "82vh")};
  min-height: 600px;
  width: 100%;
  z-index: 1;
  transition: 0.5s;
  background-color: ${colorsRGB.black(0.4)};
  overflow: hidden;
`;

const MediaContainer = styled(Container)<{ isHomepage?: boolean }>`
  width: 100%;
  height: ${(props) => (props.isHomepage ? "100vh" : "82vh")};
  min-height: 600px;
  position: relative;
`;

const ContentContainer = styled(Container)<{ isHomepage?: boolean }>`
  position: absolute;
  margin: auto;
  width: 100%;
  height: ${(props) => (props.isHomepage ? "100vh" : "82vh")};
  min-height: 600px;
  z-index: 2;
`;

const BlocksContainer = styled(Container)<{
  isHomepage?: boolean;
  isPower?: boolean;
}>`
  color: ${colors.white};
  align-items: ${(props) => (props.isHomepage ? "center" : "baseline")};
  text-align: ${(props) => (props.isHomepage ? "center" : "left")};
  justify-content: center;
  width: ${(props) =>
    props.isHomepage ? "80%" : props.isPower ? "40%" : "52%"};
  margin: ${(props) => (props.isHomepage ? "auto" : "auto auto auto 12vw")};
  flex-direction: column;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: ${(props) => (props.isHomepage ? "80%" : "45%")};
  }
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: ${(props) => (props.isHomepage ? "80%" : "60%")};
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 78%;
  }
`;

const AnimatedArrowContainer = styled(Container)`
  justify-content: center;
  position: absolute;
  align-items: center;
  left: -35px;
  right: 0;
`;

const ButtonContainer = styled(Container)`
  flex-direction: row;
  margin: 15px 0 0 0;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

interface mediaProps {
  media: Maybe<SanityFlightImageOrVideo>[];
}

const RenderMedia = ({ media }: mediaProps) => {
  if (media && media.length > 0) {
    const backgroundMedia = media[0];
    if (isSanityFlightImage(backgroundMedia)) {
      return <Image image={backgroundMedia} objectFit="cover" />;
    }

    if (isSanityVideoUpload(backgroundMedia)) {
      return (
        <Video
          key={backgroundMedia._key}
          url={backgroundMedia.url}
          volume={false}
          pause={true}
          isVisible={true}
        />
      );
    }
  }

  return null;
};

interface Props {
  heroData: Maybe<SanityHero> | undefined;
  isHomepage?: boolean;
  isPower?: boolean;
}

const Hero = ({ heroData, isPower, isHomepage }: Props) => {
  if (heroData == null) {
    return <P color="errorRed">Error loading hero</P>;
  }
  const { media, blockContent, buttonLinks } = heroData;
  if (media == null) {
    return null;
  }
  const Buttons = () => {
    if (buttonLinks && buttonLinks.length > 0) {
      const firstButton = buttonLinks[0];
      const secondButton = buttonLinks[1];
      return (
        <ButtonContainer>
          {firstButton?.url && (
            <DefaultButton
              theme="white"
              text={`${firstButton?.linktext}`}
              linkTo={`${firstButton?.url}`}
              padding="10px 40px"
              mobileMargin="auto"
              dimensions={{ height: "46px" }}
            />
          )}
          {secondButton?.url && (
            <DefaultButton
              theme="white"
              text={`${secondButton?.linktext}`}
              linkTo={`${secondButton?.url}`}
              margin="0 0 0 30px"
              mobileMargin="20px 0 0 0"
              padding="10px 40px"
              dimensions={{ height: "46px" }}
            />
          )}
        </ButtonContainer>
      );
    }
    return null;
  };

  return (
    <Container justifyContent="center">
      <Overlay isHomepage={isHomepage} />
      <MediaContainer isHomepage={isHomepage}>
        <RenderMedia media={media} />
      </MediaContainer>
      <ContentContainer isHomepage={isHomepage}>
        <BlocksContainer isHomepage={isHomepage} isPower={isPower}>
          <BlocksContent isHero blocks={blockContent?._rawColumnContent} />
          <Buttons />
        </BlocksContainer>
        <AnimatedArrowContainer>
          <AnimatedArrowBlock isHomepage={isHomepage} />
        </AnimatedArrowContainer>
      </ContentContainer>
    </Container>
  );
};

export default Hero;
