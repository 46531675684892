import { Dispatch, SetStateAction } from "react";
import {
  assets,
  buttonStyle,
  colors,
  colorsRGB,
  fontWeights,
} from "./constants";

import {
  SanityBlockContent,
  SanityCarousel,
  SanityCarouselWithButton,
  SanityCarouselWithParagraph,
  SanityFlightImage,
  SanityFullWidthImageBlock,
  SanityImage,
  SanityVideo,
  SanityFeatureBlockArray,
  SanityLatestNewsBlock,
  SanityLeftRightBlock,
  SanityGetInTouchBlock,
} from "@graphql-types";

export type Dimensions = {
  width?: string;
  height?: string;
};

export type Asset = keyof typeof assets;
export type Color = keyof typeof colors;
export type ColorRGB = keyof typeof colorsRGB;
export type ButtonTheme = keyof typeof buttonStyle;
export type ButtonType = typeof buttonStyle;
export type FontWeights = keyof typeof fontWeights;
export type AssetType = typeof assets;

export type SetRegion = Dispatch<
  SetStateAction<{
    iban: string;
    langCode: string;
  }>
>;

export type AnimationDirection = {
  to: {
    transform?: string;
    opacity?: number;
  };
  from: {
    transform?: string;
    opacity?: number;
  };
};

export function isSanityImage(data: any): data is SanityImage {
  return data._type === "image";
}

export function isSanityFlightImage(data: any): data is SanityFlightImage {
  return (data as SanityFlightImage)._type === "flightImage";
}

export function isSanityVideoUpload(data: any): data is SanityVideo {
  return (data as SanityVideo)._type === "video";
}

export function isSanityLeftRightBlock(
  data: any
): data is SanityLeftRightBlock {
  return (data as SanityLeftRightBlock)._type === "leftRightBlock";
}
export function isSanityFullWidthImageBlock(
  data: any
): data is SanityFullWidthImageBlock {
  return (data as SanityFullWidthImageBlock)._type === "fullWidthImageBlock";
}

export function isSanityCarousel(data: any): data is SanityCarousel {
  return (data as SanityCarousel)._type === "carousel";
}

export function isSanityCarouselWithParagraph(
  data: any
): data is SanityCarouselWithParagraph {
  return (
    (data as SanityCarouselWithParagraph)._type === "carouselWithParagraph"
  );
}

export function isSanityCarouselWithButton(
  data: any
): data is SanityCarouselWithButton {
  return (data as SanityCarouselWithButton)._type === "carouselWithButton";
}

export function isSanityBlockContent(data: any): data is SanityBlockContent {
  return (data as SanityBlockContent)._type === "blockContent";
}
export function isSanityFeatureBlockArray(
  data: any
): data is SanityFeatureBlockArray {
  return (data as SanityFeatureBlockArray)._type === "featureBlockArray";
}

export function isSanityLatestNewsBlock(
  data: any
): data is SanityLatestNewsBlock {
  return (data as SanityLatestNewsBlock)._type === "latestNewsBlock";
}

export function isSanityGetInTouchBlock(
  data: any
): data is SanityGetInTouchBlock {
  return (data as SanityGetInTouchBlock)._type === "getInTouchBlock";
}
